import { DatePicker, Slider } from 'antd';
import StepDisplay from "../StepDisplay";
import orderPropType from "../../proptypes/OrderPropType";
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';

BalancePaymentStep.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
    stepOrder: PropTypes.number.isRequired,
};

export default function BalancePaymentStep ({order, setOrder, stepOrder}) {
    let balancePayment = 100 - order.initialPayment;
    const defaultBalancePaymentDate = dayjs.utc().format();
    const [balancePaymentDate, setBalancePaymentDate] = useState(order.balancePaymentDate ?? defaultBalancePaymentDate);
    const [edited, setEdited] = useState(false);
    const handleComplete = () => {
        setOrder({
            ...order, 
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            balancePaymentDate,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
    };

    const handleUpdate = () => {
        setOrder({
            ...order, 
            balancePaymentDate,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    updatedAt: dayjs.utc().format()
                }
            }
        });
        setEdited(false);
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Complete balance payment"}
        description={"Make sure you have completed the balance payment and enter the date when the balance payment was completed."}
        showSkipButton
        showContinueButton
        continueButtonText={"Payment completed"}
        onComplete={handleComplete}
        onSkip={handleComplete}
        onUpdate={handleUpdate}
        edited={edited}
        onCancelEdition={() => {
            setBalancePaymentDate(order.balancePaymentDate ?? defaultBalancePaymentDate);
            setEdited(false);
        }}
    >
        <p className='description'>The remaining percentage has been calculated using the the initial payment percentage</p>
        <div className="step-input">
            <span>Balance payment date</span>
            <DatePicker
                defaultValue={dayjs(balancePaymentDate)}
                value={dayjs(balancePaymentDate)}
                onChange={(date) => {
                    setEdited(true);
                    setBalancePaymentDate(date.utc().format());
                }} 
            />
        </div>
        <Slider 
            defaultValue={balancePayment}
            value={balancePayment}
            tooltip={{ open: true }}
            onChange={()=> {}}
            
        />
    </StepDisplay>;
}