import "../styles/stepDisplay.css";
import { Button, Tooltip} from 'antd';
import { BiTimeFive } from "react-icons/bi";
import PropTypes from 'prop-types';
import orderPropType from "../proptypes/OrderPropType";

StepDisplay.propTypes = {
    children: PropTypes.node,
    stepOrder: PropTypes.number.isRequired,
    order: orderPropType.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    showSkipButton: PropTypes.bool,
    showContinueButton: PropTypes.bool,
    continueButtonText: PropTypes.string,
    onComplete: PropTypes.func,
    onUpdate: PropTypes.func,
    edited: PropTypes.bool,
    onCancelEdition: PropTypes.func,
    onSkip: PropTypes.func,
};

export default function StepDisplay ({
    children, stepOrder, order, title, description, showSkipButton, 
    showContinueButton, continueButtonText, onComplete, onUpdate, edited, onCancelEdition,
    onSkip}) {

    const state = stepOrder > order.state ? 'inactive' : stepOrder === order.state ? 'active' : edited ? 'edited' : 'completed';

    return <div id={`step-${stepOrder}`} className={`step-display ${state}`}>
            {order.stepsLogs[stepOrder] && 
                <Tooltip className="information-icon" placement="right" title={`Completed at: ${order.stepsLogs[stepOrder].completedAt ?? ""}`} arrow>
                    <BiTimeFive/>
                </Tooltip>
            }
            <p className="title">{title}</p>
            <p className="description">{description}</p>
            {children}
            <div className="step-actions">
                {edited && stepOrder < order.state && <Button 
                    className="skip-button amazops-button"
                    disabled={state === 'inactive'}
                    onClick={() => onCancelEdition()}>
                        Cancel
                </Button>}
                {showSkipButton && stepOrder >= order.state && <Button 
                    className="skip-button amazops-button" 
                    disabled={state === 'inactive'}
                    onClick={onSkip}>
                        Skip
                </Button>}
                {!['completed', 'edited'].includes(state) && showContinueButton && <Button 
                    className="continue-button amazops-button"
                    disabled={state === 'inactive'}
                    onClick={onComplete}>
                        {continueButtonText}
                </Button>}
                {state === 'edited' && <Button 
                    className="continue-button amazops-button"
                    disabled={state === 'inactive'}
                    onClick={onUpdate}>
                        Update
                </Button>}
            </div>
        </div>;
}