import { Auth } from 'aws-amplify';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL;

export const queryUserMetadata = async () => {
    return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            return axios.get(`${url}/metadata`, configuration);
        });
};

export const createCustomerPortalSession = async () => {
    return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const input = {
                "returUrl": window.location.origin
            };
            return axios.post(`${url}/customer-portal-session`, input, configuration);
        });
};

export const updateUserMetadata = async (name, value) => {
    return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const metadata = {
                name,
                value
            };
            return axios.post(`${url}/metadata`, metadata, configuration);
        });
};