import { Auth } from 'aws-amplify';
import axios from 'axios';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

const url = process.env.REACT_APP_API_URL;

export const updateOrder = async (order) => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true') {
        localStorage.setItem(`ORDER#${order.orderId}`, JSON.stringify(order));
    } else {
        return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            return axios.put(`${url}/orders/${order.orderId}`, order, configuration);
        });
    }
};

export const getOrder = async (orderId) => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true') {
        return {
            data: JSON.parse(localStorage.getItem(`ORDER#${orderId}`))
        };
    } else {
        return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            return axios.get(`${url}/orders/${orderId}`, configuration);
        });
    }
    
};

export const getOrders = async () => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true') {
        return {
            data: Object.keys(localStorage)
                        .filter(key => key.startsWith("ORDER#"))
                        .map(key => JSON.parse(localStorage.getItem(key)))
        };
    } else {
        return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            return axios.get(`${url}/orders`, configuration);
        });
    }
};

export const createOrder = async (order) => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true') {
        localStorage.setItem(`ORDER#${order.orderId}`, JSON.stringify(order));
        return {
            data: {
                ...order,
                orderId: v4(),
                createdAt: dayjs.utc().format()
            }
        };
    } else {
        return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            return axios.post(`${url}/orders`, order, configuration);
        });
    }
};

export const deleteOrder = async (orderId) => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true') {
        localStorage.removeItem(`ORDER#${orderId}`);
        return {};
    } else {
        return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            return axios.delete(`${url}/orders/${orderId}`, configuration);
        });
    }
};

export const getCalendarEvents = async () => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true') {
        return {
            data: Object.keys(localStorage)
                        .filter(key => key.startsWith("ORDER_EVENTS#"))
                        .map(key => JSON.parse(localStorage.getItem(key)))
        };
    } else {
        return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            return axios.get(`${url}/orders-events`, configuration);
        });
    }
};