import "../../styles/stepDisplay.css";
import { Progress } from 'antd';
import StepDisplay from "../StepDisplay";
import orderPropType from "../../proptypes/OrderPropType";
import PropTypes from 'prop-types';
import dayjs from "dayjs";

WaitForShipment.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
    stepOrder: PropTypes.number.isRequired,
};

export default function WaitForShipment ({order, setOrder, stepOrder}) {

    const importDays = order.importDays;
    const startDate = dayjs(order.importStartDate).format("DD/MM/YYYY");
    const endDate = dayjs(order.importStartDate).add(importDays, 'day').format("DD/MM/YYYY");
    const calculatedPercentage = dayjs().diff(dayjs(order.importStartDate), 'days') * 100 / importDays;
    const displayPercentage = Math.ceil(calculatedPercentage) > 100 ? 100 : Math.ceil(calculatedPercentage) < 0 ? 0 : Math.ceil(calculatedPercentage);
    const handleComplete = () => {
        setOrder({
            ...order, 
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Wait for shipment"}
        description={"Time to wait until the shipment arrives to Amazon’s warehouse. You can check all the imports on the \"Imports\" section on the menu on the left"}
        showSkipButton
        showContinueButton={displayPercentage === 100}
        continueButtonText={"Complete"}
        onComplete={handleComplete}
        onSkip={handleComplete}
    >
        <Progress className="step-progress" percent={displayPercentage} />
        <div className="progress-dates">
            <span>{startDate}</span>
            <span>{endDate}</span>
        </div>
    </StepDisplay>;
}