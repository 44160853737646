import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import orderPropType from "../../proptypes/OrderPropType";
import StepDisplay from "../StepDisplay";

ShareShippingLabelsWithTransportAgentStep.propTypes = {
    order: orderPropType,
    setOrder: PropTypes.func.isRequired,
    stepOrder: PropTypes.number.isRequired,
};

export default function ShareShippingLabelsWithTransportAgentStep ({order, setOrder, stepOrder}) {
    const handleComplete = () => {
        setOrder({
            ...order,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Share shipping labels with your transport agent"}
        description={"Make sure that you have shared the shipping labels for each box with your transport agent and asked them to stick them on each side of the boxes"}
        showSkipButton
        showContinueButton
        continueButtonText={"Complete"}
        onComplete={handleComplete}
        onSkip={handleComplete}
    >
    </StepDisplay>;
}