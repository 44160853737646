import { Tooltip } from "antd";
import PropTypes from 'prop-types';

CalendarEvent.propTypes = {
    event: PropTypes.shape({
        sku: PropTypes.string.isRequired,
        eventDate: PropTypes.string.isRequired,
        eventType: PropTypes.string.isRequired,
        importType: PropTypes.string,
        orderId: PropTypes.string.isRequired,
    }).isRequired,
};

const getEventIcon = (eventType) => {
    return eventType.includes("payment") ? '💰'
        : eventType.includes("Import") ? '🚢' 
        : eventType.includes("Production") ? '🏭'
        : ''; 
};

const getEventTitle = (sku, eventType) => {
    return `[${sku}] ${eventType}`;
};

export default function CalendarEvent ({event}) {
    return <Tooltip placement="topRight" title={getEventTitle(event.sku, event.eventType)} arrow>
        <span>{getEventIcon(event.eventType)} {getEventTitle(event.sku, event.eventType)}</span>
    </Tooltip>;  
}