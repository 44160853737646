export const OrderEvents = {
    ORDER_CREATED: 'Order created',
    CLICK_CREATE_ORDER: 'Click create order',
    ORDER_DELETED: 'Order deleted',
    ORDER_EDITED: 'Order edited',
    VIEW_DETAILS: 'View details',
};

export const ImportEvents = {
    VIEW_DETAILS: 'View details',
};

export const SettingsEvents = {
    EMAIL_UPDATED: 'Email updated',
    PASSWORD_UPDATED: 'Password updated',
};