import "../../styles/calendar.css";
import { Calendar, Divider, Skeleton } from 'antd';
import { useCalendarEvents } from "../../hooks/orders";
import CalendarEvent from "../../components/CalendarEvent";

export default function AmazopsCalendar () {

  const calendarEvents = useCalendarEvents();

  const dateCellRender = (value) => {
    if (!calendarEvents) return <></>;
    const yearEvents = calendarEvents[value.year()] ?? {};
    const monthEvents = yearEvents[value.month() + 1] ?? {};
    const dayEvents = monthEvents[value.date()] ?? [];
    return (
      <ul className="events">
        {dayEvents.map((event, index) => {
          return <li key={index}>
              <CalendarEvent event={event}/>
              {index < (dayEvents.length - 1) && <Divider></Divider>}
          </li>;
        })}
      </ul>
    );
  };

  return (calendarEvents ? 
    <div id="calendar">
      <h2>Calendar</h2>
      <p>On this calendar you can check all the important dates like: production deadlines, imports estimated arrival dates, balance payment dates and many more</p>
      <Calendar dateCellRender={dateCellRender}/>
    </div> 
    : <Skeleton active paragraph={{ rows: 18 }} />);
}