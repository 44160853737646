import React from 'react';
import ReactDOM from 'react-dom/client';
import '@aws-amplify/ui-react/styles.css';
import './index.css';
import "./styles/authenticator.css";
import reportWebVitals from './reportWebVitals';
import Root from "./routes/root";
import {
  createBrowserRouter,
} from "react-router-dom";
import ErrorPage from './error-page';
import Orders from './routes/orders';
import Order from './routes/orders/Order';
import Imports from './routes/imports';
import AmazopsCalendar from './routes/calendar';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import components from "./utils/authenticatorConfiguration";
import { initializeOrders } from './utils/initialize';
import Settings from './routes/settings';
import ReactGA from 'react-ga';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Billing from './components/Billing';
import { setCookie } from './utils/utils';

dayjs.extend(utc);
//if (process.env.NODE_ENV !== 'production') Amplify.Logger.LOG_LEVEL = "DEBUG";
Amplify.configure(awsExports);
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true') initializeOrders();

if (new URLSearchParams(window.location.search).get("referral")) {
  setCookie('referral', new URLSearchParams(window.location.search).get("referral"), 7);
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Orders/>,
      },
      {
        path: "orders",
        element: <Orders/>,
      },
      {
        path: "orders/:orderId",
        element: <Order/>,
      },
      {
        path: "imports",
        element: <Imports/>,
      },
      {
        path: "calendar",
        element: <AmazopsCalendar />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Authenticator
          components={components}
          >
            <Billing router={router}></Billing>
        </Authenticator>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
