import { capitalizeFirst } from "./utils";

export const cleanOrder = (order) => {
    return {
        ...order,
        destinationCountry: capitalizeFirst(order.destinationCountry),
        importType: capitalizeFirst(order.importType),
    };
};

export const getDefaultImportDaysByImportType = (importType) => {
    if (importType === 'Plane') return 15;
    if (importType === 'Ship') return 50;
    if (importType === 'Truck') return 45;
    if (importType === 'Train') return 50;
};

export const getStateLabel = (stepsConfiguration, orderState) => {
    const stepConfiguration = stepsConfiguration.find(stepConfiguration => stepConfiguration.order === orderState);
        return stepConfiguration ? stepConfiguration.label : '';
};