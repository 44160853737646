import { Result } from 'antd';
import StepDisplay from "../StepDisplay";
import orderPropType from "../../proptypes/OrderPropType";
import PropTypes from 'prop-types';

BalancePaymentStep.propTypes = {
    order: orderPropType.isRequired,
    stepOrder: PropTypes.number.isRequired,
};

export default function BalancePaymentStep ({order, stepOrder}) {
    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
    >
        <Result
            status="success"
            title="You have completed this order!"
        />
    </StepDisplay>;
}