import '../../styles/order.css';
import { useParams } from 'react-router-dom';
import { useOrder } from '../../hooks/orders';
import OrderSummary from '../../components/OrderSummary';
import { Skeleton, notification } from 'antd';
import InitialPaymentStep from '../../components/steps/InitialPaymentStep';
import CreateSKULabelsStep from '../../components/steps/CreateSKULabelsStep';
import ShareSKULabelsStep from '../../components/steps/ShareSKULabelsStep';
import ProductionInformationStep from '../../components/steps/ProductionInformationStep';
import WaitProductionCompletionStep from '../../components/steps/WaitProductionCompletionStep';
import AskForBoxesInformation from '../../components/steps/AskForBoxesInformation';
import BalancePaymentStep from '../../components/steps/BalancePaymentStep';
import SendGoodsToTransportAgentStep from '../../components/steps/SendGoodsToTransportAgentStep';
import ShareTrackingNumberWithTransportAgentStep from '../../components/steps/ShareTrackingNumberWithTransportAgentStep';
import CreateShippingPlanStep from '../../components/steps/CreateShippingPlanStep';
import CompleteTransportPaymentStep from '../../components/steps/CompleteTransportPaymentStep';
import AddTrackingNumberToAmazonSellerCentral from '../../components/steps/AddTrackingNumberToAmazonSellerCentral';
import WaitForShipment from '../../components/steps/WaitForShipment';
import CompletedStep from '../../components/steps/CompletedStep';
import { useContext, useEffect, useState } from 'react';
import { updateOrder } from '../../service/order';
import ShareShippingLabelsWithTransportAgentStep from '../../components/steps/ShareShippingLabelsWithTransportAgent';
import AskForImportTrackingNumberStep from '../../components/steps/AskForImportTrackingNumberStep';
import EditOrderModal from '../../components/EditOrderModal';
import { StepsConfigurationContext } from '../../context/StepsConfigurationContext';

export default function Order () {
    const {orderId} = useParams();
    const [order, setOrder] = useOrder(orderId);
    const [showEditOrderModal, setShowEditOrderModal] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const stepsConfiguration = useContext(StepsConfigurationContext);

    useEffect(() => {
        if (order) {
            updateOrder(order)
                .catch((error) => {
                    console.error(error);
                    api.error({
                        message: "Error",
                        description: "Could not edit the order. Please try later",
                        placement: "topRight",
                    });
                });
        }
    }, [order]);

    useEffect(() => {
        if (order) {
            const step = document.querySelector( `#step-${order.state}` );
            step && step.scrollIntoView( { behavior: 'smooth', block: 'center' } );
        }
    }, [order]);

    const handleEditOrder = (order) => {
        setOrder(order);
    };

    const renderSteps = () => {

        const steps = {
            "initial-payment": <InitialPaymentStep key="initial-payment" order={order} setOrder={setOrder} stepOrder={getStepOrder("initial-payment")}/>,
            "create-sku-label": <CreateSKULabelsStep key="create-sku-label" order={order} setOrder={setOrder} stepOrder={getStepOrder("create-sku-label")}/>,
            "share-sku-labels": <ShareSKULabelsStep key="share-sku-labels" order={order} setOrder={setOrder} stepOrder={getStepOrder("share-sku-labels")}/>,
            "production-information": <ProductionInformationStep key="production-information" order={order} setOrder={setOrder} stepOrder={getStepOrder("production-information")}/>,
            "wait-production-completion": <WaitProductionCompletionStep key="wait-production-completion" order={order} setOrder={setOrder} stepOrder={getStepOrder("wait-production-completion")}/>,
            "ask-boxes-information": <AskForBoxesInformation key="ask-boxes-information" order={order} setOrder={setOrder} stepOrder={getStepOrder("ask-boxes-information")}/>,
            "balance-payment": <BalancePaymentStep key="balance-payment" order={order} setOrder={setOrder} stepOrder={getStepOrder("balance-payment")}/>,
            "send-goods-to-transport-agent": <SendGoodsToTransportAgentStep key="send-goods-to-transport-agent" order={order} setOrder={setOrder} stepOrder={getStepOrder("send-goods-to-transport-agent")}/>,
            "share-tracking-number-with-trasnport-agent": <ShareTrackingNumberWithTransportAgentStep key="share-tracking-number-with-trasnport-agent" order={order} setOrder={setOrder} stepOrder={getStepOrder("share-tracking-number-with-trasnport-agent")}/>,
            "create-shipping-plan": <CreateShippingPlanStep key="create-shipping-plan" order={order} setOrder={setOrder} stepOrder={getStepOrder("create-shipping-plan")}/>,
            "share-shipping-labels-with-transport-agent": <ShareShippingLabelsWithTransportAgentStep key="share-shipping-labels-with-transport-agent" order={order} setOrder={setOrder} stepOrder={getStepOrder("share-shipping-labels-with-transport-agent")}/>,
            "complete-transport-payment": <CompleteTransportPaymentStep key="complete-transport-payment" order={order} setOrder={setOrder} stepOrder={getStepOrder("complete-transport-payment")}/>,
            "ask-for-import-tracking-number": <AskForImportTrackingNumberStep key="ask-for-import-tracking-number" order={order} setOrder={setOrder} stepOrder={getStepOrder("ask-for-import-tracking-number")}/>,
            "add-tracking-number-to-amazon": <AddTrackingNumberToAmazonSellerCentral key="add-tracking-number-to-amazon" order={order} setOrder={setOrder} stepOrder={getStepOrder("add-tracking-number-to-amazon")}/>,
            "wait-for-shipment": <WaitForShipment key="wait-for-shipment" order={order} setOrder={setOrder} stepOrder={getStepOrder("wait-for-shipment")}/>,
            "completed": <CompletedStep key="completed" order={order} stepOrder={getStepOrder("completed")}></CompletedStep>,
        };
        
        return stepsConfiguration
                .sort((a, b) => (a.order > b.order) ? 1 : -1)
                .map(stepConfiguration => steps[stepConfiguration.stepName]);
    };

    const getStepOrder = (stepName) => {
        const stepConfiguration = stepsConfiguration.find(stepConfiguration => stepConfiguration.stepName === stepName);
        return stepConfiguration ? stepConfiguration.order : -1;
    };

    return <div id="order">
        {contextHolder}
        {order ? 
            <OrderSummary order={order} setShowEditOrderModal={setShowEditOrderModal}/>
            : <Skeleton active paragraph={{ rows: 2 }} />
        }
        {order ? 
            renderSteps()
            : <Skeleton active paragraph={{ rows: 18 }} />
        }
        {order && 
            <EditOrderModal 
                order={order}
                showEditOrderModal={showEditOrderModal}
                setShowEditOrderModal={setShowEditOrderModal}
                onOrderEdit={handleEditOrder}

            />
        }
    </div>;
}