import { Button, Input } from 'antd';
import { useState } from "react";
import orderPropType from "../../proptypes/OrderPropType";
import PropTypes from 'prop-types';
import StepDisplay from "../StepDisplay";
import dayjs from 'dayjs';

AddTrackingNumberToAmazonSellerCentral.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
    stepOrder: PropTypes.number.isRequired,
};

export default function AddTrackingNumberToAmazonSellerCentral ({order, setOrder, stepOrder}) {

    const [showCopiedConfirmationText, setShowCopiedConfirmationText] = useState(false);
    const handleComplete = () => {
        setOrder({
            ...order, 
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
    };

    const handleCopyTrackingNumber = () => {
        navigator.clipboard.writeText(order.importTrackingNumber);
        setShowCopiedConfirmationText(true);
        setTimeout(() => setShowCopiedConfirmationText(false), 3000);
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Add tracking number to Amazon Seller Central"}
        description={"Do not forget adding the import tracking number to all the boxes on the shipment on Amazon Seller Central. You can find the tracking number below:"}
        showSkipButton
        showContinueButton
        continueButtonText={"Confirm"}
        onComplete={handleComplete}
        onSkip={handleComplete}
    >
        <div className="copy-tracking-number-container">
            <div className="step-input">
                <span>Tracking number</span>
                <Input
                    value={order.importTrackingNumber}
                    disabled
                />
            </div>
            <Button
                className="continue-button amazops-button"
                onClick={handleCopyTrackingNumber}>
                    Copy
            </Button>
        </div>
        {showCopiedConfirmationText && <span className='tracking-number-confirmation-text'>Tracking number copied! ✅</span>}
    </StepDisplay>;
}