import PropTypes from 'prop-types';

const orderPropType = PropTypes.shape({
    sku: PropTypes.string.isRequired,
    state: PropTypes.number.isRequired,
    unitsAmount: PropTypes.number.isRequired,
    destinationCountry: PropTypes.string.isRequired,
    importType: PropTypes.string.isRequired,
    initialPayment: PropTypes.number,
});

export default orderPropType;