import { useState, useEffect } from 'react';
import { getCalendarEvents, getOrder, getOrders } from '../service/order';

export function useOrder(orderId) {
    const [order, setOrder] = useState();

    useEffect(() => {
        getOrder(orderId)
        .then(response => setOrder(response.data))
        .catch(error => console.log("Error", error));
    }, []);
    
    return [order, setOrder];
}

export function useOrders() {
    const [orders, setOrders] = useState();

    useEffect(() => {
        getOrders()
        .then(response => setOrders(response.data))
        .catch(error => console.log("Error", error));
    }, []);
    
    return [orders, setOrders];
}

export function useCalendarEvents() {
    const [calendarEvents, setCalendarEvents] = useState();

    useEffect(() => {
        getCalendarEvents()
        .then(response => setCalendarEvents(response.data))
        .catch(error => console.log("Could not retrieve calendar events", error));
    }, []);
    
    return calendarEvents;
}