import orderPropType from '../proptypes/OrderPropType';
import { Progress } from 'antd';
import dayjs from 'dayjs';

ImportDisplay.propTypes = {
    order: orderPropType.isRequired
};

export default function ImportDisplay ({order}) {
    const importDays = order.importDays;
    const startDate = dayjs(order.importStartDate).format("DD/MM/YYYY");
    const endDate = dayjs(order.importStartDate).add(importDays, 'day').format("DD/MM/YYYY");
    const daysOnTransit = dayjs().diff(dayjs(order.importStartDate), 'days');
    const remainingDays = importDays - daysOnTransit < 0 ? 0 : importDays - daysOnTransit;
    const calculatedPercentage = daysOnTransit * 100 / importDays;
    const displayPercentage = Math.ceil(calculatedPercentage) > 100 ? 100 : Math.ceil(calculatedPercentage) < 0 ? 0 : Math.ceil(calculatedPercentage);
    return <div>
            <h3>{`Order: ${order.orderId}`}</h3>
            <div className='import-display'>
                <div className='import-details'>
                    <div className='import-detail'>
                        <span className='detail-title'>SKU: </span>
                        <span>{order.sku}</span>
                    </div>
                    <div className='import-detail'>
                        <span className='detail-title'>Units: </span>
                        <span>{order.unitsAmount}</span>
                    </div>
                    <div className='import-detail'>
                        <span className='detail-title'>Boxes: </span>
                        <span>{order.shippingBoxes.reduce((accumulator, currentValue) => accumulator + currentValue.numberOfBoxes, 0)}</span>
                    </div>
                    <div className='import-detail'>
                        <span className='detail-title'>Destination Country: </span>
                        <span>{order.destinationCountry}</span>
                    </div>
                    <div className='import-detail'>
                        <span className='detail-title'>Tracking number: </span>
                        <span>{order.importTrackingNumber}</span>
                    </div>
                    
                </div>
                <div className='import-progress'>
                    <Progress percent={displayPercentage} />
                    <div className="progress-dates">
                        <span>{startDate}</span>
                        <span className='days-left'>{`${remainingDays} days left`}</span>
                        <span>{endDate}</span>
                    </div>
                </div>
           </div>
        </div>;
}