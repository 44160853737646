import { Alert, Button, Divider, InputNumber } from 'antd';
import PropTypes from 'prop-types';

BoxInformation.propTypes = {
    boxNumber: PropTypes.number.isRequired,
    canDelete: PropTypes.bool,
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    initialBoxInformation: PropTypes.shape({
        id: PropTypes.string.isRequired,
        weight: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        depth: PropTypes.number.isRequired,
        numberOfBoxes: PropTypes.number.isRequired,
        units: PropTypes.number.isRequired,
    }),
    blockEdit: PropTypes.bool,
};

export default function BoxInformation ({boxNumber, canDelete, onRemove, onChange, initialBoxInformation, blockEdit}) {

    let boxInformation = {...initialBoxInformation};
    const getBoxError = () => {
        const boxVolume = boxInformation.width * boxInformation.height * boxInformation.depth;
        const minimumVolume = 15.2 * 10 * 2.5;
        if (boxVolume < minimumVolume) return "Amazon requirement: The box cannot be smaller than 15.2cm x 10cm x 2.5cm";
        if (boxInformation.width > 63.5 || boxInformation.height > 63.5 || boxInformation.depth > 63.5) return "Amazon requirement: Box dimensions cannot be longer than 63.5cm";
        if (boxInformation.weight < 0.15) return "Amazon requirement: Box weight cannot be smaller than 0.15 Kg";
        if (boxInformation.weight > 23) return "Amazon requirement: Box weight cannot be greater than 23 Kg";
        if (boxInformation.units === 0) return "Units cannot be zero";
        if (boxInformation.numberOfBoxes === 0) return "Number of boxes cannot be zero";
    };

    const getBoxWarning = () => {
        if (boxInformation.weight > 15) return "Need to stick \"Team Lift\"/\"Heavy Package\" label";
    };

    boxInformation.error = getBoxError();
    boxInformation.warning = getBoxWarning();

    return <div className='box-information-wrapper'>
            <div className="box-information">
            <span>{`Box #${boxNumber}`}</span>
            <div className="step-input">
                <span>Weight (Kg)</span>
                <InputNumber 
                    min={0} 
                    max={100}
                    value={boxInformation.weight}
                    onChange={weight => {
                        boxInformation = {
                            ...boxInformation,
                            weight: weight ?? 0,
                            error: getBoxError()
                        };
                        onChange(boxInformation);
                    }}
                    disabled={blockEdit}
                    />
            </div>
            <div className="step-input">
                <span>Height (cm)</span>
                <InputNumber 
                    min={0} 
                    max={100} 
                    value={boxInformation.height}
                    onChange={height => {
                        boxInformation = {
                            ...boxInformation,
                            height: height ?? 0,
                            error: getBoxError()
                        };
                        onChange(boxInformation);
                    }}
                    disabled={blockEdit}
                    />
            </div>
            <div className="step-input">
                <span>Width (cm)</span>
                <InputNumber 
                    min={0} 
                    max={100} 
                    value={boxInformation.width}
                    onChange={width => {
                        boxInformation = {
                            ...boxInformation,
                            width: width ?? 0,
                            error: getBoxError()
                        };
                        onChange(boxInformation);
                    }}
                    disabled={blockEdit}
                    />
            </div>
            <div className="step-input">
                <span>Depth (cm)</span>
                <InputNumber 
                    min={0} 
                    max={100} 
                    value={boxInformation.depth}
                    onChange={depth => {
                        boxInformation = {
                            ...boxInformation,
                            depth: depth ?? 0,
                            error: getBoxError()
                        };
                        onChange(boxInformation);
                    }}
                    disabled={blockEdit}
                />
            </div>
            <Divider type="vertical" />
            <div className="step-input">
                <span># Boxes</span>
                <InputNumber 
                    min={0} 
                    max={100} 
                    value={boxInformation.numberOfBoxes}
                    onChange={numberOfBoxes => {
                        boxInformation = {
                            ...boxInformation,
                            numberOfBoxes: numberOfBoxes ?? 0,
                            error: getBoxError()
                        };
                        onChange(boxInformation);
                    }}
                    disabled={blockEdit}
                    addonBefore={<span>📦</span>}
                />
            </div>
            <div className="step-input">
                <span>Units</span>
                <InputNumber 
                    min={0}
                    value={boxInformation.units}
                    onChange={units => {
                        boxInformation = {
                            ...boxInformation,
                            units: units ?? 0,
                            error: getBoxError()
                        };
                        onChange(boxInformation);
                    }}
                    disabled={blockEdit}
                />
            </div>
            {canDelete && <Button className="skip-button amazops-button" onClick={onRemove}>Remove</Button>} 
        </div>
        {boxInformation.error && 
            <Alert
                description={boxInformation.error}
                type="error"
                showIcon
            />
        }
        {boxInformation.warning && 
            <Alert
                description={boxInformation.warning}
                type="warning"
                showIcon
            />
        }
    </div>;
}