import Logo from "../logo-horizontal.png";

const components = {
    Header() {  
      return (
        <div id="authorizer-logo-container">
            <img src={Logo} alt="Amazops"></img>
        </div>
      );
    },
  };

  export default components;