import "../styles/createOrderModal.css";
import { Button, Input, InputNumber, Modal, Select, notification } from "antd";
import PropTypes from 'prop-types';
import { useContext, useState } from "react";
import { createOrder } from "../service/order";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import { ORDERS } from "../utils/GoogleAnalyticsCategories";
import { OrderEvents } from "../utils/GoogleAnalyticsEvents";
import { FBACountriesContext } from "../context/FBACountriesContext";

CreateOrderModal.propTypes = {
    showCreateOrderModal: PropTypes.bool.isRequired,
    setShowCreateOrderModal: PropTypes.func.isRequired,
    onOrderCreation: PropTypes.func.isRequired,
};

export default function CreateOrderModal({showCreateOrderModal, setShowCreateOrderModal, onOrderCreation}) {

    const [sku, setSku] = useState("");
    const [unitsAmount, setUnitsAmount] = useState(100);
    const [importType, setImportType] = useState("Plane");
    const [destinationCountry, setDestinationCountry] = useState("United States");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const fbaCountries = useContext(FBACountriesContext);


    const isValidOrder = () => {
        return sku !== "" && unitsAmount !== 0;
    };

    const handleCreateOrder = async () => {
        if (isValidOrder()) {
            
            setIsLoading(true);
            const newOrder = {
                sku,
                unitsAmount,
                importType,
                destinationCountry
            };
            createOrder(newOrder)
                .then((response) => {
                    onOrderCreation(response.data);
                    setShowCreateOrderModal(false);
                    setIsLoading(false);
                    ReactGA.event({
                        category: ORDERS,
                        action: OrderEvents.ORDER_CREATED
                    });
                    navigate(`/orders/${response.data.orderId}`);
                })
                .catch(error => {
                    console.log(error);
                    setShowCreateOrderModal(false);
                    api.error({
                        message: "Error",
                        description: "Could not create the order. Please try later",
                        placement: "topRight",
                      });
                });
        }
    };

    return <Modal
        className="create-order-modal"
        title="Create a new order"
        centered
        onCancel={() => setShowCreateOrderModal(false)}
        open={showCreateOrderModal}
        footer={[
            <Button
                key="cancel"
                className="amazops-button skip-button"
                onClick={() => setShowCreateOrderModal(false)}>
                    Cancel
            </Button>,
            <Button
                key="create"
                className="amazops-button success-button"
                loading={isLoading}
                onClick={handleCreateOrder}>
                    Create Order
            </Button>
          ]}
    >
        <div className='create-order-product-section'>
            {contextHolder}
            <div className="amazops-input">
                <span>Product SKU</span>
                <Input
                    value={sku}
                    onChange={(event) => setSku(event.target.value)}
                />
            </div>
            <div className="amazops-input">
                <span>Destination country</span>
                <Select 
                    value={destinationCountry}
                    onChange={(value) => setDestinationCountry(value)}
                    style={{width: "150px"}}
                    options={fbaCountries.map(country => ({ value: country.name, label: country.name}))}
                />
            </div>
        </div>
        <div className='create-order-product-section'>
            <div className="amazops-input">
                <span>Number of units</span>
                <InputNumber
                    min={1} 
                    value={unitsAmount}
                    onChange={newUnitsAmount => setUnitsAmount(newUnitsAmount)}
                    />
            </div>
            <div className="amazops-input transport-type">
                <span>Import type</span>
                <Select
                    value={importType}
                    style={{width: "150px"}}
                    options={[
                        { value: 'Plane', label: 'Plane' },
                        { value: 'Train', label: 'Train' },
                        { value: 'Ship', label: 'Ship' },
                        { value: 'Truck', label: 'Truck'},
                    ]}
                    onChange={newImportType => setImportType(newImportType)}
                />
            </div>
        </div>
    </Modal>;
}