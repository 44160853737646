import StepDisplay from "../StepDisplay";
import PropTypes from 'prop-types';
import orderPropType from "../../proptypes/OrderPropType";
import { useState } from "react";
import { DatePicker, InputNumber } from "antd";
import dayjs from 'dayjs';

ProductionInformationStep.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
    stepOrder: PropTypes.number.isRequired,
};

export default function ProductionInformationStep ({order, setOrder, stepOrder}) {
    const defaultProductionDeadline = 15;
    const defaultProductionStartDate = dayjs.utc().format();
    const [productionDeadline, setProductionDeadline] = useState(order.productionDeadline ?? defaultProductionDeadline);
    const [productionStartDate, setProductionStartDate] = useState(order.productionStartDate ?? defaultProductionStartDate);
    const [edited, setEdited] = useState(false);

    const handleComplete = () => {
        setOrder({
            ...order, 
            productionDeadline,
            productionStartDate,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
        setEdited(false);
    };
    
    const handleUpdate = () => {
        setOrder({
            ...order, 
            productionDeadline,
            productionStartDate,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    updatedAt: dayjs.utc().format()
                }
            }
        });
        setEdited(false);
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Production information"}
        description={"Fill the below information regarding the starting day of the production and the production deadline"}
        showSkipButton
        showContinueButton
        continueButtonText={"Confirm"}
        onComplete={handleComplete}
        onUpdate={handleUpdate}
        edited={edited}
        onCancelEdition={() => {
            setProductionDeadline(order.productionDeadline ?? defaultProductionDeadline);
            setProductionStartDate(order.productionStartDate ?? defaultProductionStartDate);
            setEdited(false);
        }}
        onSkip={handleComplete}
    >
        <div className="step-input production-start-date-input">
            <span>Production start date</span>
            <DatePicker
                defaultValue={dayjs(productionStartDate)}
                value={dayjs(productionStartDate)}
                onChange={(date) => {
                    setEdited(true);
                    setProductionStartDate(date.utc().format());
                }} 
            />
        </div>
        <div className="step-input">
            <span>Production deadline in days</span>
            <InputNumber 
                min={1} 
                max={50} 
                defaultValue={defaultProductionDeadline} 
                value={productionDeadline}
                onChange={(value) => {
                    setEdited(true);
                    setProductionDeadline(value);
                }}
            />
        </div>
    </StepDisplay>;
}