import { Auth } from "aws-amplify";
import axios from 'axios';

const url = process.env.REACT_APP_API_URL;

export const getFBACountries = async () => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true') {
        return {
            data:  ['Spain', 'Germany', 'Italy', 'United', 'United', 'France']
        };
    } else {
        return Auth.currentSession()
        .then(res => {
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            return axios.get(`${url}/fba-countries`, configuration);
        });
    }
};