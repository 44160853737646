import "../../styles/stepDisplay.css";
import { DatePicker, InputNumber, Select } from 'antd';
import { useEffect, useState } from "react";
import orderPropType from "../../proptypes/OrderPropType";
import PropTypes from 'prop-types';
import StepDisplay from "../StepDisplay";
import { getDefaultImportDaysByImportType } from "../../utils/order";
import dayjs from "dayjs";

CompleteTransportPaymentStep.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
    stepOrder: PropTypes.number.isRequired,
};

export default function CompleteTransportPaymentStep({order, setOrder, stepOrder}) {

    const [edited, setEdited] = useState(false);
    const [importType, setImportType] = useState(order.importType);
    const [importDays, setImportDays] = useState(order.importDays ?? getDefaultImportDaysByImportType(importType));
    const [importStartDate, setImportStartDate] = useState(order.importStartDate ?? dayjs.utc().format());

    useEffect(() => {
        setImportType(order.importType);
        setImportDays(order.importDays ?? getDefaultImportDaysByImportType(importType));
    }, [order]);

    const handleComplete = () => {
        setOrder({
            ...order, 
            importType,
            importDays,
            importStartDate,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
        setEdited(false);
    };
    
    const handleUpdate = () => {
        setOrder({
            ...order, 
            importType,
            importDays,
            importStartDate,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    updatedAt: dayjs.utc().format()
                }
            }
        });
        setEdited(false);
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Complete transport payment"}
        description={"Complete the transport agent payment to ship the goods to Amazon warehouse"}
        showSkipButton
        showContinueButton
        continueButtonText={"Confirm"}
        onComplete={handleComplete}
        onUpdate={handleUpdate}
        edited={edited}
        onCancelEdition={() => {
            setImportType(order.importType);
            setImportDays(order.importDays ?? getDefaultImportDaysByImportType(order.importType));
            setEdited(false);
        }}
        onSkip={handleComplete}
    >
        <div className="complete-transport-payment-container">
            <div className="step-input transport-type">
                <span>Transport type</span>
                <Select
                    defaultValue={importType}
                    value={importType}
                    options={[
                        { value: 'Plane', label: 'Plane' },
                        { value: 'Train', label: 'Train' },
                        { value: 'Ship', label: 'Ship' },
                        { value: 'Truck', label: 'Truck'},
                    ]}
                    onChange={newImportType => {
                        setImportType(newImportType);
                        setEdited(true);
                        setImportDays(getDefaultImportDaysByImportType(newImportType));
                    }}
                />
            </div>
            <div className="step-input production-start-date-input">
                <span>Import start date</span>
                <DatePicker
                    defaultValue={dayjs(importStartDate)}
                    value={dayjs(importStartDate)}
                    onChange={(date) => {
                        setEdited(true);
                        setImportStartDate(date.utc().format());
                    }} 
                />
            </div>
            <div className="step-input">
                <span>Days to reach Amazon warehouse</span>
                <InputNumber 
                    min={1} 
                    value={importDays}
                    onChange={(newImportDays) => {
                        setImportDays(newImportDays);
                        setEdited(true);
                    }}
                />
            </div>
        </div>
    </StepDisplay>;
}