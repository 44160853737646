import BoxInformation from "../BoxInformation";
import StepDisplay from "../StepDisplay";
import orderPropType from "../../proptypes/OrderPropType";
import PropTypes from 'prop-types';
import dayjs from "dayjs";

CreateShippingPlanStep.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
    stepOrder: PropTypes.number.isRequired,
};

export default function CreateShippingPlanStep({order, setOrder, stepOrder}) {
    
    const shippingBoxes = order.shippingBoxes ?? [];
    const handleComplete = () => {
        setOrder({
            ...order, 
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Create shipping plan on Amazon"}
        description={"Create the shipping plan on Amazon and share the shipping labels with your transport agent. You can find the boxes information bellow:"}
        showSkipButton
        showContinueButton
        continueButtonText={"Confirm"}
        onComplete={handleComplete}
        onSkip={handleComplete}
    >
        {shippingBoxes.map((boxInformation, index) => {
            return <BoxInformation 
                key={boxInformation.id}
                boxNumber={index}
                initialBoxInformation={boxInformation}
                onChange={() => {}}
                blockEdit/>;
        })}
    </StepDisplay>;
}